/* Custom Colors */
/* #F1F3F8, #D6E0F0, #8D93AB, #393B44 */

body {
    font-family: 'Roboto', sans-serif;
}

img {
    margin-top: 10px;
    margin-bottom: 10px;
}

p {
    text-align: justify;
}

section {
    /* border-top: 2px solid #8D93AB; */
    padding-top: 20px;
    padding-bottom: 20px;
}

.container-1280 {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.dark-div {
    background-color: #393B44;
}

.semi-dark-div {
    background-color: #8D93AB;
    color: white;
}

.btn {
    background-color: #8D93AB;
    color: white;
}

section li {
    margin-bottom: 10px;
}

.row-light {
    background-color: #F1F3F8;
}

.navbar {
    padding-left: 0px;
}

.navbar-nav {
    padding-left: 0px;
}

.navbar-brand {
    margin-right: 50px;
}

.nav-item .dropdown-item {
    white-space: normal;
    overflow: hidden;
    margin-top: 2px;
    margin-bottom: 2px;
}

.icon {
    width: 30px;
    height: 50px;
    padding-bottom: 20px;
}

.author-text {
    color: #949C9F;
    font-size: 0.8em;
    margin-top: auto;
    margin-bottom: auto;
}

.footer-link {
    color: #ffffff;
    font-size: 0.7em;
    margin-bottom: 5px;
}

/* Default state of .security-link */
.security-link {
    color: white;
    text-decoration: none;
}

/* Style for the targeted section */
.security-link:target {
    font-size: larger;
    text-decoration: underline;
}

.blog-link {
    font-size: 0.9em;
    margin-bottom: 5px;
    text-decoration: none;
    font-weight: bold;
}

.blog-link:visited,
.blog-link:hover {
    text-decoration: none;
    color: black;
}


.list-group-item {
    margin-bottom: 0px;
}

.bg-dark {
    background-color: #393B44 !important;
}

.text-success {
    color: #8D93AB !important;
}

.author-info {
    display: flex;
    align-items: start;
    margin-bottom: 20px;
}

.author-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
    border: 2px solid #003366;
}

.author-image-large {
    width: 400px;
    height: 400px;
    object-fit: cover;

}

.author-name {
    font-size: 1.25em;
    color: #003366;
    font-weight: 500;
}

.nav-link {}

.fixed-toc {
    position: fixed;
    top: 15%;
    left: 15px;
    width: 350px;
    background-color: #f8f9fa;
    border-radius: 5px;
    padding: 2px;
    z-index: 1000;
}

table {
    border-collapse: separate;
    border-spacing: 8px;
    /* Adjust the space between cells */
    background-color: #fff;
    /* Optional: Background color for the table */
    border: 2px;
}

table th {
    border-radius: 10px;
    height: 40px;
}

table td {
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #D5DADC;
    padding: 10px;
    vertical-align: middle;
}

tbody td:first-child {
    background-color: #D5DADC;
}

table th {
    background-color: #949C9F;
    color: white;
}

table tbody tr td:hover {
    background-color: #fff;
}

#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #393B44;
    width: 50px;
    height: 50px;
    display: none;
    text-align: center;
    line-height: 50px;
    color: white;
    /* White text color */
    cursor: pointer;
    border-radius: 10px;
    z-index: 1000;
}